import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client";

import defaultCache from "collection/graphql/cache/defaultCache";
import { trackingCallback } from "collection/graphql/client/common";
import { authLink } from "collection/graphql/client/links/authLink";
import errorLink from "collection/graphql/client/links/errorLink";

const headers = {};
headers["X-Client-Version"] = process.env.CLIENT_VERSION || "dev";

const walletClient = new ApolloClient({
  cache: defaultCache,
  link: ApolloLink.from([
    trackingCallback,
    authLink,
    errorLink,
    createHttpLink({
      credentials: "same-origin", // allow auth cookies to pass through
      headers,
      uri: "/v2.0/wallet/graphql",
    }),
  ]),
});

export { walletClient };
