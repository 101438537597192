import { gql } from "@apollo/client";

export default gql`
  query getWalletStatus {
    getWalletStatus {
      ok
      statusCode
      statusSubCode
      statusMessage
    }
  }
`;
