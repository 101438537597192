import React from "react";
import Select from "react-select";
import styled from "styled-components";
import { useWalletContext } from "context/WalletContext";
const WalletChooser = styled(({ className }) => {
    var _a;
    const { walletOptions, selectedWalletID, setSelectedWalletID } = useWalletContext();
    const handleChange = (option) => {
        var _a;
        setSelectedWalletID((_a = option === null || option === void 0 ? void 0 : option.value) !== null && _a !== void 0 ? _a : null);
    };
    const selectedOption = (_a = walletOptions.find((opt) => opt.value === selectedWalletID)) !== null && _a !== void 0 ? _a : null;
    return (React.createElement("div", { id: "wallet-chooser", className: className },
        React.createElement(Select, { id: "wallet-chooser-select", options: walletOptions, value: selectedOption, onChange: handleChange, placeholder: "Select a wallet...", isClearable: true })));
}) `
  display: flex;
  justify-content: flex-end;

  #wallet-chooser-select {
    width: 320px;

    & > div {
      //height: 41px;
    }
  }
`;
export { WalletChooser };
