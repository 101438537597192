import { useQuery } from "@apollo/client";
import { ThemeProvider } from "@bushelpowered/wallet-components";
import React, { createContext, useContext, useState, useMemo } from "react";
import { walletClient } from "collection/graphql/client/walletClient";
import GET_WALLET_STATUS from "collection/graphql/wallet/getWalletStatus";
import GET_WALLETS from "collection/graphql/wallet/getWallets";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const WalletContext = createContext(undefined);
const WalletContextProvider = ({ children }) => {
    const { data: walletStatusData, loading: walletStatusLoading } = useQuery(GET_WALLET_STATUS, {
        client: walletClient,
    });
    const statusCode = (walletStatusData === null || walletStatusData === void 0 ? void 0 : walletStatusData.getWalletStatus.statusCode) || "";
    const statusSubCode = (walletStatusData === null || walletStatusData === void 0 ? void 0 : walletStatusData.getWalletStatus.statusSubCode) || "";
    const statusMessage = (walletStatusData === null || walletStatusData === void 0 ? void 0 : walletStatusData.getWalletStatus.statusMessage) || "";
    //TODO: I'll probably skip this query completely if the walletStatus query is successful
    const { data: walletsData, loading: walletsLoading } = useQuery(GET_WALLETS, {
        client: walletClient,
    });
    console.log({ walletStatusData, walletStatusLoading, walletsData, walletsLoading });
    const [selectedWalletID, setSelectedWalletID] = useState(null);
    //TODO: Take the data from this query and format it in the same way as the walletOptions currently exist
    const walletOptions = [
        { value: "wallet-1", label: "Personal Wallet" },
        { value: "wallet-2", label: "Business Wallet" },
        { value: "wallet-3", label: "Savings Wallet" },
    ];
    const context = useMemo(() => ({
        selectedWalletID,
        setSelectedWalletID,
        statusCode,
        statusMessage,
        statusSubCode,
        walletOptions,
    }), [selectedWalletID, setSelectedWalletID]);
    return (React.createElement(WalletContext.Provider, { value: context },
        React.createElement(ThemeProvider, null,
            React.createElement(LoadingWrapper, { isLoading: walletStatusLoading || walletsLoading }, children))));
};
const useWalletContext = () => {
    const context = useContext(WalletContext);
    if (!context) {
        throw new Error("useWalletContext must be used within a WalletContextProvider");
    }
    return context;
};
export { WalletContextProvider, useWalletContext };
