import React from "react";
import { Navigate } from "react-router-dom";
import { useWalletContext } from "context/WalletContext";
import { WalletOverview } from "modules/wallet/components/WalletOverview";
import { WalletRequests } from "modules/wallet/components/WalletRequests";
import { WalletTransactions } from "modules/wallet/components/WalletTransactions";
import NavBar from "components/fl-ui/NavBar";
const WalletDashboard = () => {
    const { statusCode, statusSubCode, walletOptions } = useWalletContext();
    if (statusCode === "FORBIDDEN") {
        if (statusSubCode === "APPLICATION_DENIED" || statusSubCode === "DEACTIVATED" || statusSubCode === "SUSPENDED") {
            return React.createElement(Navigate, { replace: true, to: "/wallet/account-locked" });
        }
    }
    if (statusCode == "ERROR" || statusCode == "FORBIDDEN") {
        return React.createElement(Navigate, { replace: true, to: "/wallet/finish-setup" });
    }
    /*
    //TODO: Figure out when to show the not connected page
    if (true) {
      return <Navigate replace to="/wallet/not-connected" />;
    }
     */
    if (statusCode === "SUCCESS" && walletOptions.length == 0) {
        return React.createElement(Navigate, { replace: true, to: "/wallet/sign-up" });
    }
    return (React.createElement(NavBar, { tabsArray: [
            {
                component: React.createElement(WalletOverview, null),
                label: "Overview",
                route: "overview",
            },
            {
                component: React.createElement(WalletRequests, null),
                label: "Requests (4)",
                route: "requests",
            },
            {
                component: React.createElement(WalletTransactions, null),
                label: "Transactions",
                route: "transactions",
            },
        ] }));
};
export { WalletDashboard };
