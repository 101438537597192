import React from "react";
import styled from "styled-components";
import { useWalletContext } from "context/WalletContext";
import useDocumentTitle from "hooks/useDocumentTitle";
import { WalletCommonBlankSlateBanner } from "modules/wallet/components/WalletCommonBlankSlateBanner";
import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
const GreyErrorText = styled.p `
  color: ${GreyColors.smoke70};
`;
const FinishWalletSetupPage = () => {
    useDocumentTitle("Finish Wallet Setup");
    const { statusMessage, statusSubCode } = useWalletContext();
    const walletAppUrl = process.env.WALLET_APP_URL || "";
    return (React.createElement(WalletCommonBlankSlateBanner, { title: "Finish setting up your Wallet" },
        React.createElement("h2", null, "Unlock 4% APR cash back and enjoy all the benefits of your new account\u2014just a few steps away!"),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(Button, { color: "primary", onClick: () => (window.location.href = walletAppUrl) }, "Continue"),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("br", null),
        !!statusSubCode && (React.createElement(GreyErrorText, null,
            React.createElement("b", null,
                statusSubCode,
                ":"),
            " ",
            React.createElement("i", null, statusMessage)))));
};
export { FinishWalletSetupPage };
