import { gql } from "@apollo/client";

export default gql`
  query getWallets {
    getWallets {
      uuid
      isLinked
      isAuthorizedUser
      enterprise {
        id
        uuid
        name
      }
      wallet {
        uuid
        name
      }
    }
  }
`;
