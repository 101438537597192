import React from "react";
import { useWalletContext } from "context/WalletContext";
import useDocumentTitle from "hooks/useDocumentTitle";
const WalletRequests = () => {
    useDocumentTitle("Wallet Requests");
    const { selectedWalletID } = useWalletContext();
    return (React.createElement("div", null,
        React.createElement("h2", null, "Wallet Requests"),
        React.createElement("p", null,
            "Selected Wallet ID: ",
            selectedWalletID)));
};
export { WalletRequests };
