import { AccountLockedCard } from "@bushelpowered/wallet-components";
import React from "react";
import useDocumentTitle from "hooks/useDocumentTitle";
const WalletAccountLockedPage = () => {
    useDocumentTitle("Account Locked");
    return (React.createElement("div", null,
        React.createElement("h1", null, "Wallet Account Locked"),
        React.createElement(AccountLockedCard, null)));
};
export { WalletAccountLockedPage };
